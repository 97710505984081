import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import PercentLinks from '../../components/PercentLinks';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle';

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import ActionEvent from '../images/actionEvent.svg';
import { CallToAction, GetStarted } from '../../components/CallToAction'


import Default from '../../components/Layouts/Default';


export default class RevenueProcessAutomation extends Component {

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | Customer Lifecycle Marketing</title>
          <meta
            name="description"
            content="Leave No Prospect Behind"
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="telephony1">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Leave No Prospect Behind
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                Automatically Move Prospects Through The Funnel Based on Engagement
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
            <Box width={[1, 2 / 3]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                How Do You Automate Without Good Data?
              </PageTitle2>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Low Visibility</b></u>: today, marketing is completely reliant on sales to report back what happens with leads, but reps will only update CRM when deals are moving forward.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Missing CRM Data</b></u>: 32% of the people your reps engage with aren't in CRM as contacts and almost half of all emails won't make it into your reporting as a result.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                <u><b>Unstructured Data</b></u>: most engagement between prospect and rep is in unstructured conversational data (email, voice, etc). Simple triggers like 'keywords' aren't enough to get a good understanding of what's happening in the funnel.
              </Large>
            </Box>
            <br/><br/><br/>
           <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                Our Approach:
                <br/> 
                Hyperautomation
            </PageTitle2>
            <br/>
            <Box width={[1, 3/3]} mb={['32px', 0]}>
              <CenteredImg src={ActionEvent} alt="Salesforce process automation"/>

            </Box>
            <br/><br/><br/>
            <Box width={[1, 2/3]} mb={['32px', 0]}>
 
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Hyperautomation lets you automate the tasks that marketers rely on reps to do, eliminating their reliance on manual CRM updates entirely.
                <br/><br/>
                Imagine being able to pull prospects who reply to SDR emails into the most relevant drip campaigns and pre-schedule follow up at the exact time the customer requests without the rep doing a thing.  
                <br/><br/>That's the future of CRM.
                <br/><br/>
              </Large>
            </Box>

            <br/><br/><br/>
          </Section>

        </WrapperSolid>

       <WrapperBackground background="wave" gradientType="dark">
        <PercentLinks/>
      </WrapperBackground>

        <WrapperBackground background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>
        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
