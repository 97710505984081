import React from 'react';
import styled from 'styled-components';
import { Flex, Logo, Micro, Regular, Small, Large, colors, Button } from 'js-components';
import { Section } from '../Wrapper';
import { mobileBreakpoint } from '../../constants';

import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../PageTitle';

const PercentLinks = () => (
  <PercentLink>
    <PageTitle2 color={colors.white} mt= "1px" mb="32px">Real Customers.  Real Results.</PageTitle2>
    <LinkRow>
      <PercentItem>
        <PageTitle3 color={colors.white}>65%</PageTitle3>
        <Spacer/>
        <PageParagraph textAlign="center" color={colors.white}>More activities tracked vs. Outreach.io</PageParagraph>
        <Spacer/>
        <Button size="extraLarge" round value="Case Study" onClick={() => (window.location = 'https://blog.truly.co/2021/10/27/truly-vs-outreach-io-activity-capture-benchmark-testing/')} />
      </PercentItem>
      <PercentItem>
        <PageTitle3 color={colors.white}>35%</PageTitle3>
        <Spacer/>
        <PageParagraph textAlign="center" color={colors.white}>More accurate data vs. manual updates</PageParagraph>
        <Spacer/>
        <Button size="extraLarge" round value="Case Study" onClick={() => (window.location = 'https://blog.truly.co/2021/07/26/the-case-for-eliminating-rep-data-entry/')} />
      </PercentItem>
      <PercentItem>
        <PageTitle3 color={colors.white}>2.5X</PageTitle3>
        <Spacer/>
        <PageParagraph textAlign="center" color={colors.white}>More accurate phone numbers vs. Zoominfo</PageParagraph>
        <Spacer/>
        <Button size="extraLarge" round value="Case Study" onClick={() => (window.location = 'https://blog.truly.co/2021/11/03/case-study-fixing-phone-numbers-in-salesforce/')} />
      </PercentItem>
      <PercentItem>
        <PageTitle3 color={colors.white}>6</PageTitle3>
        <Spacer/>
        <PageParagraph textAlign="center" color={colors.white}>Net new oppts per rep/month</PageParagraph>
        <Spacer/>
        <Button size="extraLarge" round value="Case Study" onClick={() => (window.location = 'https://blog.truly.co/2021/07/26/the-case-for-eliminating-rep-data-entry/')} />
      </PercentItem>
    </LinkRow>
  </PercentLink>
);

export default PercentLinks;

const PercentLink = styled.div`
  align-items: center;
  justify: content: center;
  display: flex;
  max-width: 1080px;
  flex-direction: column;
  flex-grow: 0;
  margin-top:40px;
  margin-bottom:40px;
`;

const Spacer = styled.div`
  height: 16px;
`;

const LinkRow = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
  @media (max-width: 50em) {
    flex-direction: column;
  }
`;

const PercentItem = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  width: 25%;
  padding: 16px;
  margin: 8px;
  border-radius: 16px;
  background-color: rgba(0,0,0,0.6);
  @media (max-width: 50em) {
    width: 100%;
  }
`;